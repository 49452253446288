import axios from "axios";
import NProgress from "nprogress";

const config = {
    API_ROOT: "https://api.win-mobi.uz/",
    // API_ROOT: "http://172.28.7.98:14909/v1",
    DEFAULT_LANG_CODE: "en",
    DEFAULT_APP_LANG: "ru",
  };
  export default config;
  

NProgress.configure({
  showSpinner: true,
  trickleRate: 0.02,
  trickleSpeed: 400,
  easing: "ease",
  speed: 200,
});

const request = axios.create({
  baseURL: config.API_ROOT,
  params: {},
});

request.interceptors.request.use(
  (config) => {
    NProgress.inc();
    
    return config;
  },
  (error) => {
    // NProgress.done(true);
    console.log(error);
  }
);

request.interceptors.response.use(
  (response) => {
    // NProgress.done(true);
    return response;
  },
  (error) => {
    NProgress.done(true);
    return Promise.reject(error);
  }
);

export { request };
