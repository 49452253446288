import React, { useEffect, useState } from "react";

import WOW from "wowjs";

import Header from "./sections/Header";
import Hero from "./sections/Hero";
import Gift from "./sections/Gifts";
import WinnersList from "./sections/Winners";
import Registration from "./sections/Registration";
import Questions from "./sections/Question";
import FormSection from "./sections/Form";
import Footer from "./sections/Footer";

import Loader from "./components/Loader";

import "./Home.scss";
import { request } from "./api/baseApi";

const HomePage = () => {
    const [loading, setLoading] = useState(true);
    const [text, setText] = useState([]);

    const [lang, setLang] = useState( localStorage.getItem("lang") ? localStorage.getItem("lang") : "Рус");

    useEffect(() => {
        setLoading(true);

        new WOW.WOW({
            live: false,
        }).init();

        request.get("translation/get-words").then((res) => {
            setText(res.data);
            setTimeout(() => {
                setLoading(false);
            }, 500);
        });
    }, []);

    // useEffect(() => {
    //     if (localStorage.getItem("lang")) {
    //         setLang(localStorage.getItem("lang"));
    //     }
    //     setTimeout(() => {
    //         setLoading(false);
    //     }, 100);
    // }, [loading]);

    if (loading) return <Loader />;
    return (
        <>
            <div className="custom-container ">
                <Header load={loading} text={text} lang={lang} setLoad={setLoading} setLang={setLang} />
            </div>
            <Hero load={loading} setLoad={setLang} text={text} lang={lang} />
            <div className="custom-container ">
                <Gift text={text} lang={lang} />
            </div>

            <WinnersList text={text} lang={lang} />
            <div className="custom-container ">
                <Registration text={text} lang={lang} />
                <Questions text={text} lang={lang} />
                <FormSection text={text} lang={lang} />
                <Footer text={text} lang={lang} />
            </div>
        </>
    );
};

export default HomePage;
